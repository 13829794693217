<template>
  <div class="wrapper_card">
    <div class="card_container">
      <div class="CardHeader">
        <div class="title">Easy-to-Use Cards</div>
        <div class="titleTips">
          Virtual cards, physical cards, and cards of all brands. You'll find
          the right one for you. Enjoy shopping globally with digital
          currencies!
        </div>
      </div>
      <a-spin tip="Loading..." :spinning="loading">
        <div class="CardBottom">
          <div class="cardContent" v-for="item in CardData" :key="item.card_type_id">
            <div class="cardContentContainer">
              <div class="w-300">
                <div class="w-300-left">
                  <img v-if="item.card_type === 0 && item.card_type_id !== '60000004'"
                  src="@/assets/card1.png"
                    alt="card" />
                  <img v-if="item.card_type === 0 && item.card_type_id === '60000004'"
                  src="@/assets/card_new3.png"
                    alt="card" />
                  <img v-if="item.card_type === 1" src="@/assets/card1_new.svg" alt="" />
                  <a class="viewLink" href="javascript:void(0);"
                   @click="goCardInfo(item)">About the card</a>
                </div>
                <div class="w-300-right">
                  <div class="cardContentTitle">
                    {{ item.card_title }}
                  </div>
                  <div class="cardContentTitleTips" v-if="item.card_type === 0">
                    The card is a Prepaid card that can be used for multiple
                    scenarios including online shopping, flight purchase, etc.
                    in 200+ countries. It supports deposit in the form of
                    digital currencies such as USDT. Get more details from
                    "About the card" on the left.
                  </div>
                  <div class="cardContentTitleTips" v-if="item.card_type === 1">
                    This crypto card can be used for online shopping, airline
                    tickets, etc. in 200+ countries. Before making purchases
                    with USD, it is required to deposit equivalent amount of
                    cryptos such as USDT and NEO. Card holders must perform KYC
                    verification to ensure compliance with international
                    anti-money laundering regulations.
                  </div>
                  <div class="tips_container">
                    <a-space :size="6" direction="horizontal" class="tips_vertical">
                      <div class="cardContentTips">
                        <div class="tipsLeft">Currency</div>
                        <div class="tipsRight">{{ item.currency_type }}</div>
                      </div>
                      <div class="cardContentTips">
                        <div class="tipsLeft">Card Type</div>
                        <div class="tipsRight" v-if="!item.virtual_card">
                          Plastic Card (Plastic)
                        </div>
                        <div class="tipsRight" v-else-if="item.card_type === 0">
                          Prepaid Card (Virtual)
                        </div>
                        <div class="tipsRight" v-else-if="item.card_type === 1">
                          Crypto Card (Virtual)
                        </div>
                      </div>
                    </a-space>
                    <div class="applyBtnContainer">
                      <a-button class="applyBtn" type="primary" @click="applyBtnClick(item)">
                        <span v-if="item.status === 0">Application</span>
                        <span v-else-if="item.status === 1">My Balance</span>
                        <span v-else-if="item.status === 8">Activate</span>
                        <span v-else-if="item.status === 9">Under Review</span>
                        <span v-else>Application in Progress</span>
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { getCardList } from '@/utils/api';

export default {
  name: 'Card',
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      CardData: [],
      loading: true,
    };
  },
  created() {
    getCardList().then((res) => {
      this.loading = false;
      if (res.code === 0) {
        const CARD_IS_FORBIDEN_STATUS = 0;
        this.CardData = res.result.filter(card => card.enable !== CARD_IS_FORBIDEN_STATUS);
      }
    });
  },
  methods: {
    applyBtnClick(item) {
      const query = {
        virtualCard: item.virtual_card ? '0' : '1',
        cardTypeId: item.card_type_id,
        cardType: item.card_type,
        currency: item.currency_type,
      };
      if (item.card_application_fee) {
        query.cardApplicationFee = item.card_application_fee;
      }
      if (item.card_fee_currency) {
        query.cardFeeCurrency = item.card_fee_currency;
      }
      if (item.deposit_transaction_fee) {
        query.depositTransactionFee = item.deposit_transaction_fee;
      }

      if (item.kyc_status === 2) {
        if (item.status === 1 || item.status === 8 || item.status === 9) {
          this.$router.push({
            path: '/cardDetails',
            query,
          });
        } else {
          this.$router.push({
            path: '/applyCard',
            query,
          });
        }
      } else {
        this.changeDefaultMenu('identification');
      }
    },
    goCardInfo(item) {
      const query = {
        virtualCard: item.virtual_card ? '0' : '1',
        ct: item.card_type_id,
        cardType: item.card_type,
        currency: item.currency_type,
      };
      if (item.card_application_fee) {
        query.cardApplicationFee = item.card_application_fee;
      }
      if (item.card_fee_currency) {
        query.cardFeeCurrency = item.card_fee_currency;
      }
      if (item.deposit_transaction_fee) {
        query.depositTransactionFee = item.deposit_transaction_fee;
      }
      this.$router.push({
        path: '/cardInfo',
        query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_card {
  margin: auto;
  min-height: 800px;
  padding: 60px;
  padding-bottom: 0;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #fff;

  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    padding: 12px;
  }

  .card_container {
    max-width: 900px;
    margin: auto;

    .CardHeader {
      display: flex;
      flex-direction: column;

      .title {
        font-family: SamsungSharpSans-Bold;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        opacity: 0.8;
        color: #000;
      }

      .titleTips {
        font-family: Roboto;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        opacity: 0.3;
        margin-top: 30px;
        margin-bottom: 60px;
      }
    }

    .CardBottom {
      display: flex;
      max-width: 900px;
      margin: auto;
      flex-direction: column;

      @media (max-width: 960px) {
        flex-direction: column;
      }

      .cardContent {
        flex: 1;
        text-align: center;
        margin-bottom: 20px;

        .cardContentContainer {
          padding: 32px;
          background: #fafafa;
          border-radius: 20px;
          display: flex;
          flex-direction: column;

          @media (max-width: 960px) {
            padding: 12px;
          }

          .w-300 {
            display: flex;
            justify-content: center;
            border-radius: 20px;
            margin: auto;
            justify-content: space-between;

            @media (max-width: 960px) {
              flex-direction: column;
              align-items: center;
              padding: 32px 0;
            }

            .w-300-left {
              width: 200px;
              margin-right: 44px;

              @media (max-width: 960px) {
                flex: 1;
                margin-right: 0;
                margin-bottom: 48px;
              }

              img {
                width: 200px;
                border-radius: 10px;
                box-shadow: 0px 10px 40px rgba(0, 69, 255, 0.05);
              }

              .viewLink {
                text-decoration-line: underline;
                font-size: 12px;
                font-family: Poppins-Regular, Roboto;
                font-weight: 400;
                color: #000000;
                line-height: 14px;
                opacity: 0.6;
                margin-top: 12px;
              }
            }

            .w-300-right {
              flex: 1;
            }

            .cardContentTitle {
              font-weight: 500;
              font-size: 20px;
              line-height: 23px;
              margin-bottom: 15px;
              text-align: left;
            }

            .cardContentTitleTips {
              font-family: "Poppins-Regular";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 24px;
              color: #000000;
              opacity: 0.4;
              margin: 15px 0;
              text-align: left;
            }

            .tips_container {
              display: flex;
              justify-content: space-between;

              @media (max-width: 960px) {
                flex-direction: column;
              }
            }

            .tips_vertical {
              width: 100%;
            }

            .cardContentTips {
              display: flex;
              flex-direction: column;
              width: 160px;
              text-align: left;
              font-size: 14px;
              line-height: 14px;

              @media (max-width: 960px) {
                flex: 1;
              }

              .tipsLeft {
                color: #000;
                opacity: 0.3;
              }

              .tipsRight {
                flex: 1;
                margin-top: 10px;
              }
            }
          }

          .applyBtnContainer {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            @media (max-width: 960px) {
              margin-top: 24px;
              justify-content: center;
            }

            .applyBtn {
              display: block;
              width: 220px;
              height: 48px;
              border-radius: 8px;
              background: #0045ff;
            }
          }
        }
      }
    }
  }
}
</style>
